import Vue from "vue";

Vue.prototype.$currencyFormat = function (value) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    });
    let currencies = null;
    //  set currency from store (vuex).
    if (this.$store.state.event != null) {
        //   Comes when search event landing.
        currencies = this.$store.state.event.eventScope.data.currenciesScopes.data;
    }

    if (this.$store.state.currentEvent != null) {
        //   Comes when login like agency and another roles console.
        currencies = this.$store.state.currentEvent.eventScope.data.currenciesScopes.data;
    }

    if (this.$store.state.currentEventForAdmin != null) {
        //   Comes when login like agency and another roles console.
        currencies = this.$store.state.currentEventForAdmin.eventScope.data.currenciesScopes.data;
    }

    if (currencies != null && currencies.length > 0) {
        let totalLocal;
        let localCode;
        let formattedResult = "";

        // Encontrar la moneda principal
        const mainCurrency = currencies.find(currency => currency.currencyMain);

        if (mainCurrency) {
            // Definir la tasa de conversión de la moneda principal
            const mainExchangeRate = parseFloat(mainCurrency.exchangeRate);

            // Calcular el valor total en la moneda principal
            totalLocal = value * mainExchangeRate;
            localCode = mainCurrency.currency.data.code.toUpperCase();

            // Construir el resultado con la moneda principal
            formattedResult += `${formatter.format(totalLocal)} ${localCode}`;

            // Calcular y agregar los valores en las monedas secundarias
            currencies.forEach(currency => {
                if (!currency.currencyMain) {
                    const secondaryExchangeRate = parseFloat(currency.exchangeRate);
                    const totalForeign = totalLocal * secondaryExchangeRate;
                    const foreignCode = currency.currency.data.code.toUpperCase();
                    formattedResult += ` (${formatter.format(totalForeign)} ${foreignCode})`;
                }
            });

            return formattedResult;
        } else {
            // Si no se encuentra una moneda principal, devolver un mensaje de error
            return "No se encontró una moneda principal en la lista de divisas.";
        }
    } else {
        // Si no hay datos de divisas, devolver un mensaje de error
        return "No se proporcionaron datos de divisas.";
    }

};

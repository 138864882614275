<template>
	<div>
		<header>
			<AutoLogout v-if="this.$auth.loggedIn"></AutoLogout>
		</header>
		<main>
			<NavBar>
				<template v-slot:navBarContainer>
					<NavBarContainer :key="contentKey">
						<template v-slot:content>
							<nuxt />
							<notifications group="foo" />
						</template>
					</NavBarContainer>
				</template>
			</NavBar>
		</main>
		<footer>
			<YmFooter />
		</footer>
	</div>
</template>

<script>
import YmFooter from "../components/YMFooter";
import AutoLogout from "@/components/AutoLogout";
import NavBar from "@/components/adminmaster/NavBar.vue";
import NavBarContainer from "@/components/adminmaster/NavBarContainer.vue";
export default {
	name: "adminmaster",
	components: { NavBar, NavBarContainer, YmFooter, AutoLogout },
	data(){
		return {
			contentKey: 0,
		}
	},
	created() {
		this.$root.$on("forceRerender", () => {
			this.forceRerender();
		});
	},
	methods: {
		forceRerender() {
			this.contentKey += 1;
		}
	}
};
</script>

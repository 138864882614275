<template>
	<div>
		<client-only>
			<header>
				<AutoLogout v-if="this.$auth.loggedIn"></AutoLogout>
				<ym-navbar />
			</header>
			<main>
				<nuxt />
				<notifications group="foo" />
			</main>
			<footer>
				<ym-footer />
			</footer>
		</client-only>
	</div>
</template>

<script>
import YmFooter from "../components/YMFooter";
import YmNavbar from "../components/YMNavbar";
import AutoLogout from "@/components/AutoLogout";

export default {
	components: { YmNavbar, YmFooter, AutoLogout },
	methods: {
		handleBreakpoint() {
			document.body.classList.remove(
				"breakpoint--is-xs",
				"breakpoint--is-sm",
				"breakpoint--is-md",
				"breakpoint--is-lg",
				"breakpoint--is-xl"
			);
			document.body.classList.add(`breakpoint--is-${this.$mq}`);
		},
	},
	mounted() {
		window.addEventListener("load", () => {
			this.handleBreakpoint();
		});

		window.addEventListener("resize", () => {
			this.handleBreakpoint();
		});
	},
};
</script>

<template>
  <ym-section class="ym-footer-section bg-dark" variant="dark">
    <template v-slot:afterContainer>
      <b-container>
        <b-row class="mb-5 pb-5 pb-lg-0">
          <b-col lg="2" class="mb-5 mb-lg-0">
            <b-link href="/">
              <b-img :src="logoPath" fluid width="200"></b-img>
            </b-link>
            <nuxt-link :to="switchLocalePath('en')">English</nuxt-link>
            <nuxt-link :to="switchLocalePath('es')">Español</nuxt-link>
          </b-col>

          <b-col lg="3" class="mb-5 mb-lg-0">
            <h5 class="footer-title">{{ $t("footer.telefono") }}</h5>
            <ul class="footer-list list-unstyled">
              <li v-if="formattedAgency">
                <a :href="`tel:${formattedAgency.phone}`" class="text-light">{{ formattedAgency.phone }}</a>
              </li>
              <li v-for="(phone, index) in formattedAgency?.phones || []" :key="index">
                <a :href="`tel:${phone}`" class="text-light">{{ phone.phone }}</a>
              </li>
              <li v-if="!formattedAgency">
                <a href="tel:5519119970" class="text-light">5519119970</a>
              </li>
              <li v-if="!formattedAgency">
                <a href="tel:5519119969" class="text-light">5519119969</a>
              </li>
            </ul>
          </b-col>

          <b-col lg="3" class="mb-5 mb-lg-0">
            <!-- Horario Section -->
            <div class="footer-section">
              <h5 class="footer-title mb-1">{{ $t("footer.horario") }}</h5>
              <!-- Reduce margin-bottom to bring title and text closer -->
              <ul class="footer-list list-unstyled mb-4">
                <!-- Increase margin-bottom to add space between horario and direccion -->
                <li style="color: white;">
                  {{
                    formattedAgency?.profile
                        ? formattedAgency.profile.openingHours
                        : $t("footer.horario_default")
                  }}
                </li>
              </ul>
            </div>

            <!-- Dirección Section -->
            <div class="footer-section">
              <h5 class="footer-title mb-1">{{ $t("footer.direccion") }}</h5>
              <ul class="footer-list list-unstyled">
                <li style="color: white;">
                  {{
                    formattedAgency?.profile
                        ? formattedAgency.profile.address
                        : "Alfonso XIII 189 depto 405 Álamos 03400 Ciudad de México. CDMX"
                  }}
                </li>
              </ul>
            </div>
          </b-col>


          <b-col lg="3" class="mb-5 mb-lg-0">
            <h5 class="footer-title">{{ $t("footer.correos") }}</h5>
            <ul class="footer-list list-unstyled">
              <li v-for="(email, index) in formattedAgency?.emails || []" :key="index">
                <a v-if="email.email" :href="`mailto:${email.email}`" class="text-light">{{ email.emailModel.name }}:
                  {{ email.email }}</a>
              </li>
              <li v-if="!formattedAgency">
                <a href="mailto:join@travelesoft.com" class="text-light">join@travelesoft.com</a>
              </li>
              <li v-if="!formattedAgency">
                <a href="mailto:support@travelesoft.com" class="text-light">support@travelesoft.com</a>
              </li>
              <li v-if="!formattedAgency">
                <a href="mailto:train@travelesoft.com" class="text-light">train@travelesoft.com</a>
              </li>
            </ul>
          </b-col>
        </b-row>

        <b-row class="ym-footer-brand">
          <b-col lg="6" xl="4" class="text-center">
            {{ $t("footer.copy") }}
          </b-col>
          <b-col xl="4" order="first" order-xl="2" class="mb-5 mb-xl-0">
            <div class="ym-social-buttons text-center" v-if="formattedAgency?.socialMedia[0].id > 0">
              <b-button
                  v-for="(social, index) in formattedAgency.socialMedia"
                  :key="index"
                  v-if="social.url && social.active"
                  variant="icon-only"
                  :href="social.url"
                  target="_blank"
                  rel="nofollow"
                  class="mr-3"
              >
                <img
                    v-if="social.socialMediaModel.imageUrl"
                    :src="social.socialMediaModel.imageUrl"
                    :alt="social.socialMediaModel.name"
                    style="width: 24px; height: 24px;"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </ym-section>
</template>

<script>
import YmSection from "./YMSection";
import {mapGetters, mapState} from "vuex";

export default {
  name: "ym-footer",
  components: {YmSection},
  data() {
    return {
      mount: 1500,
      formattedAgency: {
        id: 0,
        name: null,
        email: null,
        age: 0,
        phone: null,
        phoneCode: null,
        languageCode: null,
        active: false,
        socialMedia: [
          {
            id: 0,
            url: null,
            active: false,
            socialMediaId: 0,
            agencyUserId: 0,
            socialMediaModel: {
              id: 0,
              name: null,
              description: null,
              imageUrl: null,
              active: false
            }
          }
        ],
        phones: [
          {
            id: 0,
            description: null,
            phone: null,
            active: false,
            phoneId: 0,
            agencyUserId: 0,
            phoneModel: {
              id: 0,
              name: null,
              description: null,
              active: false
            }
          }
        ],
        bankAccounts: [
          {
            id: 0,
            businessName: null,
            bankName: null,
            branchName: null,
            accountNumber: null,
            cardNumber: null,
            clabeNumber: null,
            active: false,
            bankAccountId: 0,
            agencyUserId: 0,
            bankAccount: {
              id: 0,
              name: null,
              description: null,
              active: false
            }
          }
        ],
        emails: [
          {
            id: 0,
            email: null,
            active: false,
            emailId: 0,
            agencyUserId: 0,
            emailModel: {
              id: 0,
              name: null,
              description: null,
              active: false
            }
          }
        ],
        profile: {
          id: 0,
          description: null,
          openingHours: '',
          profileImgUrl: null,
          bannerImgUrl: null,
          address: '',
          addressUrl: null,
          agencyUserId: 0
        },
        paymentProcessors: [
          {
            id: 0,
            name: null,
            description: null,
            email: null,
            active: false,
            agencyId: 0,
            createdAt: null,
            updatedAt: null,
            paymentMethods: {
              data: [
                {
                  id: 0,
                  name: null,
                  description: null,
                  paymentProcessorId: 0,
                  active: false,
                  createdAt: null,
                  updatedAt: null,
                  paymentProcessor: {
                    data: {
                      id: 0,
                      name: null,
                      description: null,
                      email: null,
                      active: false,
                      agency_id: 0,
                      created_at: null,
                      updated_at: null
                    }
                  }
                }
              ]
            }
          }
        ]
      },
      travelesoft: {
        'id': 0,
        'name': null,
        'email': null,
        'phone': null,
        'description': null,
        'address': null,
        'addressUrl': null,
        'logoImgUrl': null,
        'userId': 0,
        'openingHours': null,
        'active': 0,
        'socialMedia': {},
        'bankAccount': {},
        'additionalPhones': [],
        'additionalEmails': []
      }
    };
  },

  computed: {
    logoPath: function () {
      const _agency = this.formattedAgency;
      return _agency !== null ? _agency.profile?.profileImgUrl : "/assets/images/logo-white.png";
    },
    isEventRoute: function () {
      const eventPattern = /^\/eventos\/PLS-[A-Z0-9-]+(\/.*)?$/i;
      return eventPattern.test(this.$nuxt.$route.fullPath);
    },
    infoAgency: function () {
      // we have mapped the values on our store, only when the route is 'eventos' we took the information of the
      // current agency configured to our event.
      // TODO Upgrade to use auth information and remove the path approach
      /*return this.isEventRoute
          ? this.eventAgency
          : this.isAgency || this.isOrganizer
              ? this.currentAgency
              : this.agency;*/
    },
    ...mapState(["agency", "eventAgency", "generalEvent"]),
    ...mapGetters(["isAdmin", "isAgency", "isAgencyUser", "isPayment", "isWeddingPlanner", "isOrganizer", "isAttendee"]),
  },
  created() {
    this.setAgency();
  },
  methods: {
    async setAgency() {

      if (this.isEventRoute) {
        this.formattedAgency = this.generalEvent.agency;
      } else if (this.$store.state.auth.loggedIn) {
        if (this.isAdmin) {
          this.formattedAgency = null;
        } else if (this.isAttendee) {
          this.formattedAgency = await this.getAgency(this.$store.state.currentEvent.idagency);
        } else if (this.isAgency) {
          this.formattedAgency = await this.getAgency(this.$store.state.auth.user.uid);
        } else if (this.isAgencyUser || this.isPayment || this.isWeddingPlanner || this.isOrganizer) {
          this.formattedAgency = await this.getAgency(this.$store.state.currentAgency.id);
        } else {
          this.formattedAgency = await this.getAgency(this.$store.state.currentEvent.idAgency);
        }
      } else {
        this.formattedAgency = null;
      }
    },
    formatAgency(agency) {
      this.formattedAgency = {
        id: agency.idAgency,
        name: agency.name,
        phone: agency.phone1,
        email: agency.emailContact,
        openingHours: agency.schedules,
        address: agency.address,
        logoImgUrl: agency.logo,
        additionalEmails: [
          {name: 'Reply', email: agency.emailReply},
          {name: 'Support', email: agency.emailSupport}
        ],
        additionalPhones: [
          {name: '', phone: agency.phone2},
          {name: '', phone: agency.phone3}
        ]
      };
    },
    async getAgency(agencyId) {
      try {
        const response = await this.$axios.get(`api/agency/${agencyId}/bank-data`);
        return response.data;
      } catch (error) {
        this.options.message = error.response.data.error;
        this.options.show = true;
      }
    }
  }
}
</script>

<style lang="scss">
.ym-footer-section {
  padding-bottom: 2rem;
}

.ym-footer-section h5 {
  color: white;
}

.ym-footer-brand {
  font-size: 0.85rem;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.footer-title {
  font-size: 1.375rem;
  margin-bottom: 1.225rem;
}

.footer-list {
  margin-bottom: 0;

  li {
    margin-bottom: 0.5rem;
  }
}

.ym-social-buttons {
  .btn {
    svg {
      transition: $transition-base;
      fill: #fff;
    }

    @include hover-focus-active {
      svg {
        fill: $secondary;
      }
    }
  }
}
</style>

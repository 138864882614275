export default (context, inject) => {

    const hello = function() {
        let info  = [];

        let versionOS = context.$ua.osVersion().split(".");
        let browserVersion = context.$ua.browserVersion().split(".");

        info['device']          = context.$ua.deviceType();
        info['os']              = context.$ua.os();
        info['osVersion']       = versionOS[0];
        info['browser']         = context.$ua.browser();
        info['browserVersion']  = browserVersion[0];
        info['browserVendor']   = context.$ua.browserVendor();

        return info;
    }

    inject('getInfoBroser', hello)
    // For Nuxt <= 2.12, also add 👇
    context.$getInfoBroser = hello
  }

export default function ({$auth, store, redirect}) {

    if (!($auth.loggedIn &&
        (
            store.getters.isAdmin ||
            store.getters.isAgency ||
            store.getters.isOrganizer ||
            store.getters.isAgencyUser ||
            store.getters.isPayment ||
            store.getters.isWholesaler ||
            store.getters.isWeddingPlanner
        )
    )) {
        redirect('/login');
    }
}
<template>
  <div>
    <header>
      <ym-navbar/>
    </header>
    <main>
      <nuxt/>
      <notifications group="foo"/>
    </main>
    <footer>
      <ym-footer/>
    </footer>
  </div>
</template>

<script>
import YmNavbar from '../components/YMNavbar';
import YmFooter from '../components/YMFooter';

export default {
  components: {YmFooter, YmNavbar},
  methods: {
    handleBreakpoint() {
      document.body.classList.remove('breakpoint--is-xs', 'breakpoint--is-sm', 'breakpoint--is-md', 'breakpoint--is-lg', 'breakpoint--is-xl');
      document.body.classList.add(`breakpoint--is-${this.$mq}`);
    },
  },
  mounted() {
    window.addEventListener('load', () => {
      this.handleBreakpoint();
      });
      window.addEventListener('resize', () => {
        this.handleBreakpoint();
      });
    },
  };
</script>

import Vue from 'vue';
import VueMq from 'vue-mq';

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    xs: 575,
    sm: 767,
    md: 991,
    lg: 1199,
    xl: Infinity,
  },
  defaultBreakpoint: 'xs', // customize this for SSR
});

<template>
  <section class="ym-section" :class="[
      variant ? `ym-hero--${variant}` : '',
      sizeY ? `ym-section--${sizeY}` : '',
  ]">
    <div v-if="image" class="ym-section__background" v-bind:style="{ 'background-image': 'url(' + image + ')' }"></div>
    <slot name="beforeContainer"/>
    <b-container class="ym-section__container" :class="[
      sizeX ? `ym-section__container--${sizeX}` : '',
    ]">
      <h2 v-if="title" class="ym-section__title" :class="[titleClass]">{{title}}</h2>
      <slot name="beforeContent"/>
      <div v-if="$slots.default" class="ym-section__content">
        <slot/>
      </div>
      <slot name="afterContent"/>
    </b-container>
    <slot name="afterContainer"/>
  </section>
</template>

<script>
  export default {
    name: 'ym-section',
    props: {
      title: String,
      titleClass: String,
      image: String,
      variant: String,
      sizeY: String,
      sizeX: String,
    },
  };
</script>

<style lang="scss">
  .ym-section {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;

    &,
    &__background {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &__title,
    &__content,
    &__container {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__container {
      margin-bottom: 2rem;
      position: relative;

      &--md {
        @include media-breakpoint-up(md) {
          max-width: map_get($container-max-widths, md);
        }
      }

      &--lg {
        @include media-breakpoint-up(lg) {
          max-width: map_get($container-max-widths, lg);
        }
      }
    }

    &__title {
      font: $headings-font-weight 2rem $headings-font-family;
      margin-bottom: 1.5rem;
      text-align: center;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }
    }

    &__content {
      text-align: center;
      margin-bottom: 2rem;

      > *:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(md) {
        > p {
          font-size: 1.25rem;
        }
      }
    }

    &--lg {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    &--light {
      color: $dark;
    }

    &--dark {
      color: $light;
    }
  }
</style>

import Vue from 'vue';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
    fieldsBagName: 'veeFields',
    validity: true,
    events: '',
    locale: 'es',
    dictionary: {
        en: {
            messages: {
                required: (field) => `Field ${field} is required`
            }
        },
        es: {
            messages: {
                required: (field) => `El campo ${field} es requerido`,
                email : (field) => `Formato invalido`,
                decimal : (field) => `Porfavor introduzca un numero valido`
            },
            attributes :{
                name : 'Nombre',
                phone : 'Celular',
                message : 'Mensaje',
                email : 'Email',
            }
        }
    }
});

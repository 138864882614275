import Vue from "vue"

// Share common methods
if (!Vue.__ym_methods) {
    Vue.__ym_methods = true
    Vue.mixin({
        methods: {
            empty(str) {
                return (typeof str === 'string' && str.trim().length === 0);
            },
            notifySuccess(messageKeyTranslation = "snotify.mensaje.success", titleKeyTranslation = "snotify.title.exito") {
                this.$notify({
                    group: "foo",
                    type: "success",
                    title: this.$t(titleKeyTranslation ?? "snotify.title.exito"),
                    text: this.$t(messageKeyTranslation ?? "snotify.mensaje.success")
                });
            },
            notifyError(messageKeyTranslation = "snotify.mensaje.error", titleKeyTranslation = "snotify.title.error") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: this.$t(titleKeyTranslation ?? "snotify.title.error"),
                    text: this.$t(messageKeyTranslation ?? "snotify.mensaje.error"),
                });
            },
            notifyClean() {
                this.$notify({
                    group: "foo",
                    clean: true
                });
            }
        }
    })
}

<template>
  <div class="ym-hero">
    <div class="container ym-container-md">
      <h1 class="ym-hero__title" v-if="error.statusCode === 404">{{ $t('error_http.404') }}</h1>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['error'],
  };
</script>

<style scoped>
</style>
<template>
	<b-container>
		<slot name="content" />
	</b-container>
</template>

<script>
export default {
	name: "NavBarContainer",
}
</script>
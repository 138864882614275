<template>
	<b-alert v-if="warningZone" show variant="warning" class="text-center">
		Parece que has estado inactivo, ¿Sigues ahí?
	</b-alert>
</template>
<script>
import { mapActions } from "vuex";
export default {
	name: "AutoLogout",
	data() {
		return {
			events: [
				"click",
				"mousemove",
				"mousedown",
				"scroll",
				"keypress",
				"load",
			],
			warningTimer: null,
			logoutTimer: null,
			warningZone: false,
		};
	},
	mounted() {
		this.events.forEach(function (event) {
			window.addEventListener( event, () => {
					this.resetTimer();
				},
				false
			);
		}, this);

		this.setTimers();
	},
	destroyed() {
		this.events.forEach(function (event) {
			window.removeEventListener( event, () => {
					this.resetTimer();
				},
				false
			);
		}, this);

		this.resetTimer();
	},
	methods: {
		...mapActions(["login_out"]),

		setTimers() {
			this.warningTimer = setTimeout( () => this.warningMessage(), 30 * 60 * 1000); //30 mins
			this.logoutTimer = setTimeout( () => this.logoutUser(), 60 * 60 * 1000);//60 mins

			this.warningZone = false;
		},

		warningMessage() {
			this.warningZone = true;
		},

		logoutUser() {
			this.login_out();
		},

		resetTimer() {
			clearTimeout(this.warningTimer);
			clearTimeout(this.logoutTimer);
			this.setTimers();
		},
	},
};
</script>
